import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getContact } from '../selectors';
import { getContactDropdown, getContactOptions } from '../selectors/dropdowns';

import { closePopup, createAddContactPopup } from '../actions/popup';
import { createAddContact, createDeleteContact, createCopyContact } from '../actions/contact';
import { updateShop } from '../actions/shop';

import { BASE_ZINDEX } from '../popup-factory';

import Select from './Select';

class ContactListPopup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      contact_id: '',
      contact_name: '',
      contact_email: ''
    };

    this.handleAddContact = this.handleAddContact.bind(this);
    this.onChangeContact = this.onChangeContact.bind(this);
  }

  onChangeContact(contact_id) {
    if ('ADD' === contact_id) {
      this.setState({
        contact_id,
        contact_name: '',
        contact_email: ''
      }, function() {
        this.handleAddContact();
      });
    } else {
      const contact = this.props.getContact(contact_id);
      this.setState({
        ...contact,
        contact_id
      }, function() {
        this.handleAddContact();
      });
    }
  }

  handleAddContact() {
    const {
      contact_id,
      contact_name,
      contact_email
    } = this.state;

    if (!contact_id) {
      return;
    }
    if ('ADD' === contact_id) {
      this.setState({
        contact_id: '',
        contact_name: '',
        contact_email: ''
      }, () => this.props.onAddNewContact());
    } else {
      this.setState({
        contact_id: '',
        contact_name: '',
        contact_email: ''
      }, () => this.props.onAddClientContact(contact_id));
    }
  }

  render() {
    const { contacts, client_contacts, onRemoveContact, onClosePopup, title = 'Contact List', index } = this.props;
    const { contact_id, contact_name, contact_email } = this.state;

    return (
      <div className="reveal large" style={{ display: 'block', zIndex: BASE_ZINDEX + index, height: 'initial', maxHeight: '100%' }}>
        <div className="row small-12 columns">
          <h3>{title}</h3>
          <a className="button" style={{ position: 'absolute', right: '1rem', top: '1rem' }} onClick={onClosePopup}>Done</a>
        </div>
        <div className="row small-12 medium-8 columns" style={{ padding: '10px 0px', marginRight: '15px', float: 'left' }}>
          <Select className="small-8" style={{ float: 'left', marginLeft: '15px', marginRight: '10px' }} options={client_contacts} value={contact_id} placeholder="Select from client" change={this.onChangeContact} inPopup withMarginBottom />
          {/* <a className="button small" onClick={e => {e.preventDefault(); this.handleAddContact()}} disabled={!contact_id}>Add</a> */}
          {contact_id && contact_id !== 'ADD' &&
            <div className="small-12 columns">
              <p><b>{contact_name}</b></p>
              <p>{contact_email}</p>
            </div>}
        </div>
        <div className="row small-12 columns" style={{ overflow: 'auto', height: '400px' }}>
          <table>
            <thead>
              <tr>
                <td>Contact Name</td>
                <td>Actions</td>
              </tr>
            </thead>
            <tbody>
              {contacts.length ? contacts.map((c, i) =>
                <tr key={c.contact_id}>
                  <td>
                    <b>{c.contact_first_name} {c.contact_last_name}</b>
                    {c.contact_email ? ` (${c.contact_email})` : ''}
                  </td>
                  <td>
                    <a onClick={e => onRemoveContact(c, i)}>delete contact</a>
                  </td>
                </tr>
              ) : <tr><td>No contacts yet</td></tr>}
            </tbody>
          </table>
          <br /><br />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const contacts_filter = ownProps.type === 'BILLING' ? ['SHOP-BILLING', 'SHOP'] : ['SHOP-SHIPPING', 'SHOP'];
  const shop_contacts = getContactDropdown(state, ownProps).filter(c => contacts_filter.indexOf(c.company_type) !== -1);
  const shop_contact_map = shop_contacts.reduce((o, c) => { o[c.contact_first_name + c.contact_last_name + c.contact_email] = c; return o; }, {});
  return {
    contacts: shop_contacts,
    client_contacts: [{ key: 'ADD', value: 'Add New Client Contact' }].concat(getContactDropdown(state, { parent_id: ownProps.client_id }).filter(c => !shop_contact_map[c.contact_first_name + c.contact_last_name + c.contact_email]).map(c => ({ key: c.contact_id, value: `${c.contact_first_name} ${c.contact_last_name}` }))),
    getContact: contact_id => {
      const contact = getContact(state, { contact_id }) || {};
      return {
        contact_id,
        contact_name: `${contact.contact_first_name} ${contact.contact_last_name}`,
        contact_email: contact.contact_email
      };
    }
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClosePopup: () => dispatch(closePopup()),
  onRemoveContact: (contact, index) => {
    dispatch(createDeleteContact(contact));
    if (index == 0) {
      dispatch(updateShop(ownProps.parent_id, `${ownProps.type.toLowerCase()}_contact_type`, 'LIST', 'TYPED'));
    }
  },
  onAddClientContact: contact_id => dispatch(createCopyContact(contact_id, ownProps.parent_id, `SHOP-${ownProps.type}`)),
  onAddNewContact: () => {
    dispatch(createAddContactPopup(null, ownProps.client_id, null, null, ownProps.client_id, 'CLIENT', ownProps.parent_id, `SHOP-${ownProps.type}`));
  },
});

const ConnectedContactListPopup = connect(mapStateToProps, mapDispatchToProps)(ContactListPopup);
export default ConnectedContactListPopup;
